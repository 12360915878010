import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getNodeDetails, getLXCData, getVMData, stopVM, startVM, stopLXC, startLXC, refreshAuth } from '../api';
import CreateLXCContainer from './CreateLXCContainer'; // Import the new component

const REAUTH_INTERVAL_MS = 30 * 60 * 1000; // Example: 30 minutes

const NodeDetails = () => {
  const { nodeName } = useParams();
  const [nodeDetails, setNodeDetails] = useState(null);
  const [lxcData, setLXCData] = useState(null);
  const [vmData, setVMData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lxcLoading, setLXCLoading] = useState(false); // Separate loading state for LXC data
  const [vmLoading, setVMLoading] = useState(false); // Separate loading state for VM data
  const [error, setError] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [authCookie, setAuthCookie] = useState(null); // State to hold the auth cookie

  const fetchNodeDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getNodeDetails(nodeName);
      setNodeDetails(data.data);
    } catch (error) {
      setError('Error fetching node details');
    } finally {
      setLoading(false);
    }
  };

  const handleViewLXCData = async () => {
    setLXCLoading(true);
    setError(null);
    try {
      const data = await getLXCData(nodeName);
      setLXCData(data.data);
    } catch (error) {
      setError('Error fetching LXC data');
    } finally {
      setLXCLoading(false);
    }
  };

  const handleViewVMData = async () => {
    setVMLoading(true);
    setError(null);
    try {
      const data = await getVMData(nodeName);
      setVMData(data.data);
    } catch (error) {
      setError('Error fetching VM data');
    } finally {
      setVMLoading(false);
    }
  };

  const handleStopVM = async (vmId) => {
    try {
      await stopVM(nodeName, vmId);
      alert('VM stopped successfully');
      await handleViewVMData(); // Refresh VM data
    } catch (error) {
      setError('Error stopping VM');
    }
  };

  const handleStartVM = async (vmId) => {
    try {
      await startVM(nodeName, vmId);
      alert('VM started successfully');
      await handleViewVMData(); // Refresh VM data
    } catch (error) {
      setError('Error starting VM');
    }
  };

  const handleStopLXC = async (containerId) => {
    try {
      await stopLXC(nodeName, containerId);
      alert('LXC container stopped successfully');
      await handleViewLXCData(); // Refresh LXC data
    } catch (error) {
      setError('Error stopping LXC container');
    }
  };

  const handleStartLXC = async (containerId) => {
    try {
      await startLXC(nodeName, containerId);
      alert('LXC container started successfully');
      await handleViewLXCData(); // Refresh LXC data
    } catch (error) {
      setError('Error starting LXC container');
    }
  };

  const handleRefresh = async () => {
    try {
      const { cookie, csrfToken } = await refreshAuth();
      setAuthCookie(cookie); // Save the new cookie in state
      console.log('New Cookie:', cookie);
      console.log('New CSRF Token:', csrfToken);

      // Update the last refresh timestamp
      const now = new Date();
      setLastRefresh(now);

      // Calculate time left before reauthentication is needed
      const nextAuthTime = new Date(now.getTime() + REAUTH_INTERVAL_MS);
      setTimeLeft(nextAuthTime - now);
    } catch (error) {
      setError('Error refreshing authentication');
    }
  };

  useEffect(() => {
    fetchNodeDetails();
  }, [nodeName]);

  useEffect(() => {
    if (lastRefresh) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const nextAuthTime = new Date(lastRefresh.getTime() + REAUTH_INTERVAL_MS);
        setTimeLeft(nextAuthTime - now);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [lastRefresh]);

  // Convert milliseconds to readable time format
  const formatTimeLeft = (ms) => {
    if (ms <= 0) return '0 seconds';
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes} minutes ${seconds} seconds`;
  };

  return (
    <div>
      <h1>Node Details: {nodeName}</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div>
          <p>{error}</p>
          <button onClick={handleRefresh}>Refresh Authentication</button>
        </div>
      ) : (
        <div>
          <pre>{JSON.stringify(nodeDetails, null, 2)}</pre>
          <button onClick={handleViewLXCData} disabled={lxcLoading}>
            {lxcLoading ? 'Loading LXC Data...' : 'View LXC Data'}
          </button>
          {lxcData && (
            <div>
              <h2>LXC Containers</h2>
              <ul>
                {lxcData.map((lxc) => (
                  <li key={lxc.vmid}>
                    <pre>{JSON.stringify(lxc, null, 2)}</pre>
                    <button onClick={() => handleStopLXC(lxc.vmid)}>Stop</button>
                    <button onClick={() => handleStartLXC(lxc.vmid)}>Start</button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <button onClick={handleViewVMData} disabled={vmLoading}>
            {vmLoading ? 'Loading VM Data...' : 'View VM Data'}
          </button>
          {vmData && (
            <div>
              <h2>VMs</h2>
              <ul>
                {vmData.map((vm) => (
                  <li key={vm.vmid}>
                    <pre>{JSON.stringify(vm, null, 2)}</pre>
                    <button onClick={() => handleStopVM(vm.vmid)}>Stop</button>
                    <button onClick={() => handleStartVM(vm.vmid)}>Start</button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* Add CreateLXCContainer component */}
          <CreateLXCContainer nodeName={nodeName} />
          <button onClick={handleRefresh}>Refresh Authentication</button>
          {lastRefresh && (
            <div>
              <p>Last Refreshed: {lastRefresh.toLocaleString()}</p>
              <p>Time Left Before Reauth: {formatTimeLeft(timeLeft)}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NodeDetails;
