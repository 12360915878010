// NodeList.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNodes, refreshAuth } from '../api';

const NodeList = () => {
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchNodes = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getNodes();
      setNodes(data.data);
    } catch (error) {
      setError('Error fetching nodes');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNodes();
  }, []);

  const handleRefresh = async () => {
    try {
      await refreshAuth();
      fetchNodes(); // Refresh nodes after authentication
    } catch (error) {
      setError('Error refreshing authentication');
    }
  };

  const handleViewData = (nodeName) => {
    navigate(`/node/${nodeName}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <div><p>{error}</p><button onClick={handleRefresh}>Refresh</button></div>;

  return (
    <div>
      <h2>Nodes</h2>
      <button onClick={handleRefresh}>Refresh</button>
      <ul>
        {nodes.map((node) => (
          <li key={node.node}>
            {node.node}
            <button onClick={() => handleViewData(node.node)}>View Data</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NodeList;
