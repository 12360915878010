import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NodeList from './components/NodeList';
import NodeDetails from './components/NodeDetails';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<NodeList />} />
          <Route path="/node/:nodeName" element={<NodeDetails />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
