// src/components/CreateLXCContainer.js

import React, { useState } from 'react';
import axios from 'axios';



const CreateLXCContainer = ({ nodeName }) => {
  const [formData, setFormData] = useState({
    vmid: '',
    hostname: '',
    password: '',
    ostemplate: '',
    storage: '',
    cores: '',
    memory: '',
    rootfs: '',
    net0: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Update the URL to include the backend server port (5000)
      const response = await axios.post(`https://serverbackend.gohaul.co/api/nodes/${nodeName}/lxc`, formData);
      alert('LXC Container Created Successfully');
      // Optionally clear the form or handle post-submission actions
    } catch (error) {
      console.error('Error creating LXC container:', error.response ? error.response.data : error.message);
      alert('Error creating LXC container');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="vmid" value={formData.vmid} onChange={handleChange} placeholder="VMID" required />
      <input type="text" name="hostname" value={formData.hostname} onChange={handleChange} placeholder="Hostname" required />
      <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" required />
      <input type="text" name="ostemplate" value={formData.ostemplate} onChange={handleChange} placeholder="OS Template" required />
      <input type="text" name="storage" value={formData.storage} onChange={handleChange} placeholder="Storage" required />
      <input type="number" name="cores" value={formData.cores} onChange={handleChange} placeholder="CPU Cores" required />
      <input type="number" name="memory" value={formData.memory} onChange={handleChange} placeholder="Memory (MB)" required />
      <input type="text" name="rootfs" value={formData.rootfs} onChange={handleChange} placeholder="Root Filesystem" required />
      <input type="text" name="net0" value={formData.net0} onChange={handleChange} placeholder="Network Configuration" required />
      <button type="submit">Create LXC Container</button>
    </form>
  );
};

export default CreateLXCContainer;
