// api.js

import axios from 'axios';

const API_BASE_URL = 'https://serverbackend.gohaul.co';

// Load CSRF token and authCookie from environment variables
let csrfToken = process.env.CSRF_TOKEN;
let authCookie = process.env.PVE_AUTH_COOKIE;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Ensure cookies are sent with requests
  headers: {
    'CSRFPreventionToken': csrfToken,
    'Cookie': authCookie
  }
});

// Refresh authentication
export const refreshAuth = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/refresh-auth`, null, {
      withCredentials: true
    });

    // Check the response structure
    console.log('Response Data:', response.data);

    csrfToken = response.data.csrfToken;
    authCookie = response.headers['set-cookie']?.[0] || ''; // Extract cookie from response headers

    apiClient.defaults.headers['CSRFPreventionToken'] = csrfToken;
    apiClient.defaults.headers['Cookie'] = authCookie;

    return { csrfToken, authCookie };
  } catch (error) {
    console.error('Error refreshing authentication:', error);
    throw error;
  }
};

// Update existing functions to use apiClient
export const getNodes = async () => {
  try {
    const response = await apiClient.get('/api/nodes');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // CSRF token expired
      await refreshAuth();
      return getNodes(); // Retry the request
    }
    console.error('Error fetching nodes:', error);
    throw error;
  }
};

// Fetch node details
export const getNodeDetails = async (nodeName) => {
  try {
    const response = await apiClient.get(`/api/nodes/${nodeName}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching node details:', error);
    throw error;
  }
};

// Fetch LXC data
export const getLXCData = async (nodeName) => {
  try {
    const response = await apiClient.get(`/api/nodes/${nodeName}/lxc`);
    return response.data;
  } catch (error) {
    console.error('Error fetching LXC data:', error);
    throw error;
  }
};

// Fetch VM data
export const getVMData = async (nodeName) => {
  try {
    const response = await apiClient.get(`/api/nodes/${nodeName}/qemu`);
    return response.data;
  } catch (error) {
    console.error('Error fetching VM data:', error);
    throw error;
  }
};

// Stop VM
export const stopVM = async (nodeName, vmId) => {
  try {
    const response = await apiClient.post(`/api/nodes/${nodeName}/qemu/${vmId}/status/stop`, {}, {
      headers: {
        'CSRFPreventionToken': csrfToken // Use CSRF token from updated state
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error stopping VM:', error.response ? {
      status: error.response.status,
      data: error.response.data
    } : error.message);
    throw error;
  }
};

// Start VM
export const startVM = async (nodeName, vmId) => {
  try {
    const response = await apiClient.post(`/api/nodes/${nodeName}/qemu/${vmId}/status/start`, {}, {
      headers: {
        'CSRFPreventionToken': csrfToken // Use CSRF token from updated state
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error starting VM:', error.response ? {
      status: error.response.status,
      data: error.response.data
    } : error.message);
    throw error;
  }
};

// Stop LXC
export const stopLXC = async (nodeName, lxcId) => {
  try {
    const response = await apiClient.post(`/api/nodes/${nodeName}/lxc/${lxcId}/status/stop`, {}, {
      headers: {
        'CSRFPreventionToken': csrfToken // Use CSRF token from updated state
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error stopping LXC:', error.response ? {
      status: error.response.status,
      data: error.response.data
    } : error.message);
    throw error;
  }
};

// Start LXC
export const startLXC = async (nodeName, lxcId) => {
  try {
    const response = await apiClient.post(`/api/nodes/${nodeName}/lxc/${lxcId}/status/start`, {}, {
      headers: {
        'CSRFPreventionToken': csrfToken // Use CSRF token from updated state
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error starting LXC:', error.response ? {
      status: error.response.status,
      data: error.response.data
    } : error.message);
    throw error;
  }
};

// Create LXC container
export const createLXC = async (nodeName, lxcData) => {
  try {
    const response = await apiClient.post(`/api/nodes/${nodeName}/lxc`, lxcData);
    return response.data;
  } catch (error) {
    console.error('Error creating LXC container:', error);
    throw error;
  }
};
